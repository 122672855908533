import * as React from "react"
import type { HeadFC } from "gatsby"

export const Head: HeadFC = () => <title>Reach Developer Portal</title>

export default () => {
  return (
    <main>
      The actual Reach Developer Portal.
    </main>
  )
};


